<template>
    <el-card style='margin:15px'>
        <div v-if="finalizat">
            <div v-if="success" class="text-center">
                <h2>Vă mulțumim ! </h2>
                <p>Răspunsurile Dumneavoastră au fost înregistrate. Doriți să completați chestionarul și pentru celălalt tip de activitate didactică sau pentru altă Disciplină ?</p> 
                <el-button @click="refresh_page()">DA, doresc</el-button>
                <el-button @click="go_to_umfd()">NU, nu doresc</el-button>
            </div>
            <div v-else>
                <h2>Atentie ! </h2>
                <p>”Ati oferit deja feedback pentru aceasta Disciplina. Doriți a completați chestionarul și pentru o alta Disciplina?”</p> 
                <el-button @click="refresh_page()">DA, doresc</el-button>
                <el-button @click="go_to_umfd()">NU, nu doresc</el-button>
            </div>
        </div>
        <div v-if="!finalizat"> 
            <div class="text-center">
                <img src="../assets/logoumfd.png"/>
                <h2>UNIVERSITATEA DE MEDICINĂ ȘI FARMACIE „CAROL DAVILA” din BUCUREȘTI</h2>
                <h3>Facultatea de Stomatologie</h3>

                <p>Calea Plevnei nr.17-21, București, sector 1, Tel / Fax: 021.315.86.49</p>

                <p>email: decanat.medicinadentara@umfcd.ro</p>
            </div>

            <div>
                <div style="font-size:14px">
                Notă: Acest Formular nu colectează date cu caracter personal. Răspunsurile Dumneavoastră sunt în
                integralitate anonime și sunt folosite strict pentru îmbunătățirea continua a activităților didactice.
                Răspunsurile Dumneavoastră se vor referi la Disciplinele studiate în
                <span style="text-decoration: underline;   text-decoration-color: blue;  "> {{ get_semestru_curent() }} al Anului Universitar
                {{ get_an_curent() }}</span>.
                    <strong>Vă mulțumim pentru cooperare ! </strong> <br/>


                    <p>Comisia de Evaluare și Asigurare a Calității</p>
                    
                    <hr/>
                </div>
                <br/>
            </div>
            
            <el-row>
                <el-col :span="24">
                    <p>
                        <strong>SELECTAȚI ANUL DE STUDIU</strong>
                    </p>
                    <el-radio-group v-model="Raspuns.AnAles" @change="on_change_an()">
                        <el-radio :disabled="Raspuns.AnAles != ''" v-for="(an,idx) in Ani" :key="'an-' + idx" :label="an">{{an}}</el-radio>
                        <hr/>
                    </el-radio-group>
                    
                    
                </el-col>
                <el-col :span="24" v-if="Raspuns.AnAles != ''" style="display:none">
                    <p>
                        <strong>Semestrul:</strong>
                    </p>
                    <el-radio-group v-model="Raspuns.SemestruAles" @change='get_discipline()'>
                        <el-radio :disabled="Raspuns.SemestruAles != ''" v-for="(sem,idx) in Semestre" :key="'sem-' + idx" :label="sem">{{sem}}</el-radio>
                        <hr/>
                    </el-radio-group>
                    
                </el-col>
                <el-col :span="24" v-if="Raspuns.SemestruAles != ''">
                    <p>
                        <strong>SELECTAȚI DISCIPLINA:</strong>
                    </p>
                    <el-radio-group v-model="Raspuns.Disciplina" >
                        <br/>
                        <el-row>
                            <el-col :lg="12" :md="12" :xs="24" v-for="(d,idx) in info.Discipline" :key="'d-' + idx">
                                <el-radio  style="padding: 25px;" :disabled="Raspuns.Disciplina != ''"  :label="d">{{d.Nume}}</el-radio>
                            </el-col>
                        </el-row>
                        <hr/>
                    </el-radio-group>
                    
                </el-col>
                <el-col :span="24" v-if="Raspuns.Disciplina != ''">
                    <p>
                        <strong>Tip Activitate Didactica:</strong>
                    </p>
                    <el-radio-group v-model="Raspuns.CursSauLaborator" @change="get_cadre_didactice()">
                        <el-radio :disabled="Raspuns.CursSauLaborator != ''" label="curs">Curs</el-radio>
                        <el-radio :disabled="Raspuns.CursSauLaborator != ''" label="laborator" v-if="Raspuns.Disciplina.AreLaborator == '1'">LP / Stagiu / Seminar</el-radio>
                    </el-radio-group>
                    <hr/>
                </el-col>

                <el-col :span="24" v-if="Raspuns.CursSauLaborator != ''">
                    <p>
                        <strong>Selectați Titular 
                            <span v-if="Raspuns.CursSauLaborator=='curs'">Curs</span> 
                            <span v-if="Raspuns.CursSauLaborator=='laborator'">LP / stagiu / seminar</span> 
                        :
                        </strong>
                    </p>
                    <el-radio-group v-model="Raspuns.CadruDidactic">
                        <el-row>
                            <el-col :lg="12" :md="12" :xs="24" v-for="(c,idx) in info.CadreDidactice" :key="'cadru-' + idx">
                                <el-radio style="padding: 25px;" :disabled="Raspuns.CadruDidactic != ''"  :label="c">
                                    {{c.GradDidactic}}  {{c.Prenume}} {{c.Nume}}
                                </el-radio>
                            </el-col>
                        </el-row>
                    </el-radio-group>
                    <hr/>
                </el-col>

                <el-col :span="24" v-if="Raspuns.CadruDidactic != ''">
                    <el-button type="danger" v-if="previewMode" @click="cheat()">Cheat - Completeaza tot pe suficient</el-button>
                    <div v-for="(c,idxC) in Raspuns.Chestionar" :key="'ch-' + idxC">
                        <h2>{{idxC+1}}. {{c.Nume}}</h2>
                        <rand 
                            v-for="(intrebare,idx) in c.Intrebari" v-model="intrebare.value" :key="'intrebare-' + idxC + '-' + idx"
                            @input="on_raspuns_intrebare(intrebare,$event)"
                            > 
                            <template> 
                                <div style='display:inline-block' v-html="((idxC+1)+'.'+(idx+1)) + '. ' + intrebare.text"></div> 
                            </template> 
                        </rand>
                    </div>
                        

                </el-col>

                <el-col :span="24" v-if="Raspuns.CadruDidactic != ''">
                    <div style='margin-top: 25px'>
                        <strong>Sugestii:</strong>
                        <el-input v-model="Raspuns.Sugestii"/>
                    </div>
                </el-col>
                <el-col :span="24" v-if="Raspuns.CadruDidactic != ''">
                    <div style='margin-top: 25px'>
                        <strong>Am participal la un numar de </strong> 
                        
                        <el-input-number style="display:inline-block; " size="small" :controls="false"  v-model="Raspuns.NrCursuri" :min="1" :max="300"/> 
                        
                        <span v-if="Raspuns.CursSauLaborator=='curs'">cursuri</span> 
                        <span v-if="Raspuns.CursSauLaborator=='laborator'">LP-uri / stagii / seminarii</span> 
                    </div>
                </el-col>

                <el-col :span="24" v-if="Raspuns.CadruDidactic != ''" style='text-align: center; padding: 25px;'>
                    <el-button type="primary" @click="save()">Trimite raspuns</el-button>
                </el-col>

            </el-row>
        </div>
    </el-card>
    
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import RandChestionar from "../widgets/RandChestionar.vue";

import moment from 'moment';

export default {
    name: "dashboard",
    extends: BasePage,
    components: {
        'base-page'   : BasePage,
        'rand' : RandChestionar
    },
    data () {
        return {
            previewMode: false,
            info: {
                Discipline: [] ,
                CadreDidactice: [],
            },
            finalizat: false,
            success: false,
            Ani: ["1","2","3","4","5","6"],
            Semestre: ["1","2"],
            ChestionareJson: {},
            Raspuns:{
                AnAles: "",
                SemestruAles: "",
                Disciplina: "",
                CursSauLaborator: "",
                CadruDidactic: "",
                Sugestii:"",
                NrCursuri:"",
                Chestionar: []
            }
        }
    },
    methods: {
        
        on_change_an() {
            this.Raspuns.SemestruAles = this.get_semestru().toString();
            this.get_discipline();
        },

        async on_raspuns_intrebare(intrebare, raspuns){
            if( raspuns == 'insuficient' ){ 
                var _motiv = "insuficient";
                try{
                    var _dialogResponse = await this.$prompt('Pentru a putea fi validat răspunsul, este necesar sa introduceți o motivație care v-a condus la acest calificativ', 'Ati ales sa acordați calificativul INSUFICIENT!', {
                            confirmButtonText: 'Am răspuns motivat',
                            cancelButtonText: 'Nu doresc sa motivez',
                            inputPattern: /(.|\s)*\S(.|\s)*/,
                            inputErrorMessage: 'Nu ati introdus nici o motivatie'
                    });
                    _motiv = _dialogResponse.value;
                    
                } catch{ }
                //salvam motivul pe intrebare
                intrebare.Motiv = _motiv;
            }
        },

        cheat(){
            this.Raspuns.Chestionar.forEach(setIntrebari => {
                setIntrebari.Intrebari.forEach(intrebare => {
                    intrebare.value = 'suficient';
                    console.log(intrebare);    
                });
            });
        },

        get_semestru() {
            var anCurent    = parseInt( moment().format("Y") );
            var acum        = moment();
            var minSem1     = moment(`${anCurent}-03-01`, "Y-M-D");
            var maxSem1     = moment(`${anCurent}-06-01`, "Y-M-D");
            
            if( acum.isSameOrAfter(minSem1) && acum.isSameOrBefore(maxSem1) )
                return 1;
            else
                return 2;
        },
        get_semestru_curent(){
            var semestru = this.get_semestru();
            if( semestru == 1)
                return "Semestrul 1";
            else
                return "Semestrul 2";
        },

        get_an_curent() {
            var anTrecut    = parseInt( moment().format("Y") ) - 1;
            var anCurent    = parseInt( moment().format("Y") );
            return `${anTrecut}-${anCurent}`;
        },

        async get_info(){
            var response         = await this.post("chestionar/get_intrebari");
            this.ChestionareJson = response.ChestionareJson;
        },


        go_to_umfd(){
            window.location.href = "https://umfcd.ro/";
        },

        refresh_page(){
            window.location.reload();
        },

        async get_discipline() {
            var response         = await this.post("chestionar/get_discipline", { an: this.Raspuns.AnAles, semestru: this.Raspuns.SemestruAles }  );
            this.info.Discipline = response.Discipline;
        },

        async get_cadre_didactice() {
            var response             = await this.post("chestionar/get_cadre_didactice", { IdDisciplina: this.Raspuns.Disciplina.Id, Tip: this.Raspuns.CursSauLaborator }  );
            this.info.CadreDidactice = response.CadreDidactice;
            console.log(this.ChestionareJson.Continut);
            var jsonChestionar       = JSON.parse(this.ChestionareJson.Continut);
            
            

            if( this.Raspuns.CursSauLaborator == "curs" )
                this.Raspuns.Chestionar = jsonChestionar.curs;
            else
                this.Raspuns.Chestionar = jsonChestionar.laborator;
        },

        get_secret_token() {
            return this.Raspuns.Disciplina.Id + "-" + this.Raspuns.CadruDidactic.Id + "-" + this.Raspuns.CursSauLaborator;
        },

        is_form_valid() {
            var chestionar = this.Raspuns.Chestionar;
            for (let i = 0; i < chestionar.length; i++) {
                const c = chestionar[i];
                for (let j = 0; j < c.Intrebari.length; j++) {
                    const intrebare = c.Intrebari[j];
                    if( intrebare.value == "" )
                    {
                        this.$message({
                            dangerouslyUseHTMLString: true,
                            message: "Nu ati raspuns la: " + intrebare.text,
                            type:"error"
                        });
                        return false;
                    }    
                }   
            }

            if( this.Raspuns.NrCursuri == "" ) {
                this.$message({
                    message: "Completati numarul de cursuri",
                    type:"error"
                });
                return false;
            }
            return true;
        },

        async save(){
            // verificam daca am raspuns la toate intrebarile
            if(this.is_form_valid()){
                if( settings.set_info_completare( this.get_secret_token() ) ) {
                    
                    var response     = await this.post("chestionar/save", { raspuns: this.Raspuns, IdIntrebari: this.ChestionareJson.Id, Preview: this.previewMode ? '1':'0'} );
                    this.$message({
                        message: "Va multumim!",
                        type:"success"
                    });
                    this.finalizat = true;
                    this.success   = true;
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);
                } else {
                    this.$message({
                        message: "Ati completat deja acest formular! Alegeti altul",
                        type:"error"
                    });
                    this.finalizat = true;
                    this.success   = false;
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);
                }
            }

        },

    },
    mounted(){
        this.previewMode = window.location.href.toString().indexOf("/preview") != -1;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}
h2{
    margin-top: 50px;
}

</style>
