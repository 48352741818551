<template>
   <div >
        <div class="counter">
            <div class="number">{{count}}</div>
            <div class="info">Accesări</div>
        </div>
    </div>  
</template>

<script>
import BasePage from "@/pages/BasePage";
export default {
    name: "Counter",
    extends: BasePage,
    data () {
        return {
            count: "0"
        }
    },
    methods: {
        async refresh_me() {
            var response = await this.post("counter");
            this.count  = response.Counter;
        }
    },
    mounted(){
        this.refresh_me();
    }
}
</script>

<style lang="less" scoped>
    .counter{
        
        background: #0e62c7;
        padding: 10px;
        color: white;
        margin: 10px;
        width: 200px;
        text-align: center;

        .number{
            color: white;
            font-weight: bold;
            font-size: 20px;
        }
        

        .info{
            color: #b3cdea;
        }
    }
</style>