<template>
    <el-card style='margin:15px'>
        <div class="text-center">
                <img src="../assets/logoumfd.png"/>
                <h2>UNIVERSITATEA DE MEDICINĂ ȘI FARMACIE „CAROL DAVILA” din BUCUREȘTI</h2>
                <h3>Facultatea de Stomatologie</h3>

                <p>Calea Plevnei nr.17-21, București, sector 1, Tel / Fax: 021.315.86.49</p>

                <p>email: aracis.dentara@umfcd.ro</p>
            </div>

            <div>
                <div style="font-size:14px">
                    
                    <div class='text-center'>
                        <img src='../assets/inchis.jpg'/>
                    </div>
                    <p class='text-center'>
                        Perioada de trimitere a feedback-ului pentru Semestrul I, An universitar 2023 – 2024 s-a încheiat !
                    </p>
                    <p class='text-center'>
                        Vă mulțumim pentru colaborare și implicare!
                    </p>
                    <p class='text-center'>
                        După analiza și prelucrarea răspunsurilor, sinteza rezultatelor va fi publicată pe această platformă. Simultan cu publicarea rezultatelor și concluziilor, veți primi email cu notificare privind disponibilitatea acestora. 
                        Rezultatele vor rămâne prezentate până pe data de 01.10.2024 când începe:
                    </p>
                    <p class='text-center'>
                        Perioada de trimitere a feedback-ului pentru Semestrul II, An universitar 2023 – 2024
                    </p>
                    
                    
                    <hr/>

                    <div class='text-center'>
                        <el-alert title="Notă" type="info" :closable="false">
                            Acest Formular nu colectează date cu caracter personal. Răspunsurile Dumneavoastră sunt în integralitate anonime și sunt folosite strict pentru îmbunătățirea continua a activităților didactice. Răspunsurile Dumneavoastră se vor referi 
                            la Disciplinele studiate în Semestrul 1 al Anului Universitar 2023-2024. Vă mulțumim pentru cooperare !
                            <hr/>
                            Comisia de Evaluare și Asigurare a Calității
                        </el-alert>
                    </div>
                </div>
                <br/>
            </div>
    </el-card>
     
</template>

<script>
import BasePage from "../pages/BasePage";
import moment from 'moment';

export default {
    name: "dashboard",
    extends: BasePage,
    components: {
        'base-page'   : BasePage
    },
    data () {
        return { }
    },
    methods: { 
         get_semestru() {
            var anCurent    = parseInt( moment().format("Y") );
            var acum        = moment();
            var minSem1     = moment(`${anCurent}-03-01`, "Y-M-D");
            var maxSem1     = moment(`${anCurent}-05-15`, "Y-M-D");
            
            if( acum.isSameOrAfter(minSem1) && acum.isSameOrBefore(maxSem1) )
                return 1;
            else
                return 2;
        },
        get_semestru_curent(){
            var semestru = this.get_semestru();
            if( semestru == 1)
                return "Semestrul 1";
            else
                return "Semestrul 2";
        },

        get_an_curent() {
            var anTrecut    = parseInt( moment().format("Y") ) - 1;
            var anCurent    = parseInt( moment().format("Y") );
            return `${anTrecut}-${anCurent}`;
        },

    },

    mounted(){
        
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }
    h2{
        margin-top: 50px;
    }

</style>
