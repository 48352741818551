<template>
    <el-card style='margin:15px'>
        <div class="text-center">
                <img src="../assets/logoumfd.png"/>
                <h2>UNIVERSITATEA DE MEDICINĂ ȘI FARMACIE „CAROL DAVILA” din BUCUREȘTI</h2>
                <h3>Facultatea de Stomatologie</h3>

                <p>Calea Plevnei nr.17-21, București, sector 1, Tel / Fax: 021.315.86.49</p>

                <p>email: decanat.medicinadentara@umfcd.ro</p>
            </div>

            <br>
            <div>
                
                <rezultate/>
            </div>
    </el-card>
     
</template>

<script>
import BasePage from "../pages/BasePage";
import moment from 'moment';
import Rezultate from '@/widgets/Rezultate';

export default {
    name: "Analiza",
    extends: BasePage,
    components: {
        'base-page'   : BasePage,
        "rezultate": Rezultate
    },
    data () {
        return { }
    },
    methods: { 
         get_semestru() {
            var anCurent    = parseInt( moment().format("Y") );
            var acum        = moment();
            var minSem1     = moment(`${anCurent}-03-01`, "Y-M-D");
            var maxSem1     = moment(`${anCurent}-05-15`, "Y-M-D");
            
            if( acum.isSameOrAfter(minSem1) && acum.isSameOrBefore(maxSem1) )
                return 1;
            else
                return 2;
        },
        get_semestru_curent(){
            var semestru = this.get_semestru();
            if( semestru == 1)
                return "Semestrul 1";
            else
                return "Semestrul 2";
        },

        get_an_curent() {
            var anTrecut    = parseInt( moment().format("Y") ) - 1;
            var anCurent    = parseInt( moment().format("Y") );
            return `${anTrecut}-${anCurent}`;
        },

    },

    mounted(){
        
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }
    h2{
        margin-top: 50px;
    }

</style>
