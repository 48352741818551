<template>
    <el-container style="height: 100%;">
        

        <el-container style="padding: 30px 20px;" >
            <el-main style="text-align:left; padding: 0px;" v-loading='loadingVisible' >
                <slot/>
            </el-main>
        </el-container>
    </el-container>

</template>

<script>
    import settings from "./../backend/LocalSettings";
    


    export default {
        name: "base-page",
        
        data () {
            return {
                loadingVisible: false
            }
        },
        methods: {
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                } else {
                    this.$router.push("/");
                }
            },
          
        },
        mounted: function() {
            settings.verify_login_and_redirect(this);
        }
    };
</script>

<style scoped>

</style>