<template>
   <div>
        <el-row>
            <el-col :md="12" :xs="24">
                <slot/>
            </el-col>
            <el-col :md="3" :xs="6"> <el-radio class='full-width' border size='small' @change="updateInput" v-model="nota" label="insuficient">Insuficient</el-radio> </el-col>
            <el-col :md="3" :xs="6"> <el-radio class='full-width' border size='small' @change="updateInput" v-model="nota" label="suficient">suficient</el-radio> </el-col>
            <el-col :md="3" :xs="6"> <el-radio class='full-width' border size='small' @change="updateInput" v-model="nota" label="bine">bine</el-radio> </el-col>
            <el-col :md="3" :xs="6"> <el-radio class='full-width' border size='small' @change="updateInput" v-model="nota" label="foarte_bine">Foarte bine</el-radio> </el-col>
        </el-row>
            
        
    </div>  
</template>

<script>
export default {
    name: "RandChestionar",
    props: ['value'],
    data () {
        return {
            nota: ""
        }
    },
    watch:{
        value(){
            this.nota = this.value;
            console.log('acilea');
        }
    },
    methods: {
        
        updateInput () {
            this.$emit('input', this.nota );
        }
    }
}
</script>

<style>
    
</style>