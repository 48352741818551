<template>
   
    <div class="counter">
        <a :href="BaseUrl + 'downloader/analiza'" target="_blank">
            <div class="number">Analiza Feedaback-ului studenților pentru Semestrul II, Anul Universitar 2021 - 2022</div>
            <div class="info">Descărcare Analiza</div>
        </a>
    </div>

</template>

<script>
import BasePage from "@/pages/BasePage";
import settings from '@/backend/LocalSettings';

export default {
    name: "Counter",
    extends: BasePage,
    data () {
        return {
             BaseUrl: settings.BASE_URL
        }
    },
    methods: {
        
    },
    mounted(){
        
    }
}
</script>

<style lang="less" scoped>
    .counter{
        
        background: #0e62c7;
        padding: 10px;
        color: white;
        width: 100%;
        margin: 10px;
        text-align: center;

        a{
            text-decoration: none;
        }

        .number{
            color: white;
            font-weight: bold;
            font-size: 20px;
        }
        

        .info{
            color: #b3cdea;
            font-size:32px;
        }
    }
</style>